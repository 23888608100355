import React from "react";
import "./hero.css";
import BUILDING_IMG  from "../images/hero/buildings.jpeg"
import useScrollFadeIn from "../hooks/useScrollFadeIn";

const Hero = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
    3: useScrollFadeIn("image", 1, 0.3),
  };

  return (
    <div className="hero">
      <div className="info" {...animatedItem[0]}>
        <h2>종합무역회사의 중심</h2>
        <h1>MYTAS</h1>
      </div>
      <img src={BUILDING_IMG} alt="hero 이미지"/>
    </div>
  )
};

export default Hero;