import React from 'react';
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <p>경기도 부천시 원미구 길주로 77번길 19-45 디아뜨갤러리4차 제207호</p>
      <p>032-610-4006</p>
      <p>world3rd@mytas.kr</p>
      <p><b>MYTAS</b></p>
      <br/>
      <p>Copyright © 2022 mytas. All rights reserved.</p>
    </div>
  )
};

export default Footer;