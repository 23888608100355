import React from 'react';
import "./contact.css";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

const ContactUs = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0.1),
    1: useScrollFadeIn("up", 1, 0.2),
  };
  return (
    <div className="contact">
      <h3 {...animatedItem[0]}>해외수출 문의 사항</h3>
      <p {...animatedItem[1]}><a href="mailto:world3rd@mytas.kr">world3rd@mytas.kr</a> 로 문의 주세요.</p>
    </div>
  )
};

export default ContactUs;