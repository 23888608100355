import React from 'react';
import logo from './logo.svg';
import './App.css';
import Hero from "./components/Hero";
import Introduce from "./components/Introduce";
import AboutUs from "./components/AboutUs";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";

function App() {
  return (
    <div className="App">
      <Hero/>
      <Introduce/>
      <AboutUs/>
      <Partners/>
      <ContactUs/>
      <Footer/>
    </div>
  );
}

export default App;
