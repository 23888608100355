import React from 'react';
import "./about.css";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

const Introduce = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0.2),
    1: useScrollFadeIn("up", 1, 0.4),
  };

  return (
    <div className="about">
      <h3 {...animatedItem[0]}>ABOUT US</h3>
      <p {...animatedItem[1]}>
        MYTAS는 2009년 7월에 설립한 악세사리, 잡화, 화장품 등 패션잡화를 수출품목으로 다루는 종합무역회사입니다.<br/>
        설립이래 10년간 매월 50만개 이상의 상품수출납품 및 연간 70억 거래액을 달성하고 있으며,<br/> 엄격한 자체 검수시스템운영으로 불량을 최소화 하고<br/> 해외 브랜드와 신뢰와 소통을 기반으로 장기계약을 유지하고 있는 작지만 강점이 강한 확실한 회사입니다. </p>
    </div>
  )
};

export default Introduce;