import React from 'react';
import "./partners.css";
import IMG01 from "../images/partners/mcfashion.jpeg";
import IMG02 from "../images/partners/toyoshima.jpeg";
import IMG03 from "../images/partners/3coins.jpeg";
import IMG04 from "../images/partners/lattice.jpeg";
import IMG05 from "../images/partners/daily_russet.jpeg";
import IMG06 from "../images/partners/ciaopanic.jpeg";
import IMG07 from "../images/partners/chico.jpeg";
import IMG08 from "../images/partners/discoat.jpeg";
import IMG09 from "../images/partners/natural_couture.jpeg";
import IMG10 from "../images/partners/nice_claup_new.jpeg";
import IMG11 from "../images/partners/pal_group.jpeg";
import IMG12 from "../images/partners/pal_collection.jpeg";
import IMG13 from "../images/partners/salut.jpeg";
import IMG14 from "../images/partners/every_very.jpeg";
import IMG15 from "../images/partners/les_signes.jpeg";
import IMG16 from "../images/partners/olive_des_olive.jpeg";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

const parters = [
  {img: IMG01},
  {img: IMG02},
  {img: IMG03},
  {img: IMG04},
  {img: IMG05},
  {img: IMG06},
  {img: IMG07},
  {img: IMG08},
  {img: IMG09},
  {img: IMG10},
  {img: IMG11},
  {img: IMG12},
  {img: IMG13},
  {img: IMG14},
  {img: IMG15},
  {img: IMG16},
]

const Partners = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0.1),
    1: useScrollFadeIn("up", 1, 0.2),
  };
  return (
    <div className="partners">
      <h3 {...animatedItem[0]}>Partners</h3>
      <p {...animatedItem[1]}>일본 주식회사 PAL GROUP, Mitsubishi fashion 등에 의류, 액세서리등 다양한 패션 잡화를 중심으로 수출하고 있습니다.</p>
      <div className="imgs" >
        {parters.map(({img}, i) => {
          return (
            <div className="partner-wrap">
              <img key={i} src={img} alt={img}/>
            </div>
          )}
        )}
      </div>
    </div>
  )
};

export default Partners;