import React from 'react';
import "./introduce.css";
import SHIP_IMG from "../images/introduce/ship.webp";
import AIRPLANE_IMG from "../images/introduce/airplane.webp";
import useScrollFadeIn from "../hooks/useScrollFadeIn";
import useWindowSize from "../hooks/useWindowSize";

const Introduce = () => {
  const {width} = useWindowSize();
  const isMobile = width < 769;
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0.2),
    1: useScrollFadeIn("up", 1, 0.3),
    2: useScrollFadeIn("up", 1, 0.4),
  };

  return (
    <div className="introduce">
      <h3 {...animatedItem[0]}>해상・항공수출</h3>
      <div className="section" {...animatedItem[1]}>
        <div className="img-box">
          <div className="img-wrap">
            <img src={SHIP_IMG} alt="빌딩이미지"/>
          </div>
          <div className="square left"/>
        </div>
        <p className="contents">MYTAS는 해운사를 통하여 지속적으로 해상・항공수출을 하고 있습니다.
          다년간의 노하우로 사고없이 선적을 진행하고 있습니다. </p>
      </div>
      <div className="section" {...animatedItem[2]}>
        {isMobile ? (<>

          <div className="img-box">
            <div className="img-wrap">
              <img src={AIRPLANE_IMG} alt="빌딩이미지"/>
            </div>
            <div className="square right"/>
            <p className="contents">
              엄격한 자체 검수시스템을 강점으로 불량을 최소화시켜 해외 브랜드와의 높은 만족도와 신뢰를 얻은 작지만 일처리만큼은 깔끔하고 확실한 회사 입니다.
              </p>
          </div>
        </>) : (
          <>
            <p className="contents">엄격한 자체 검수시스템을 강점으로 불량을 최소화시켜 해외 브랜드와의 높은 만족도와 신뢰를 얻은 작지만 일처리만큼은 깔끔하고 확실한 회사</p>
            <div className="img-box">
              <div className="img-wrap">
                <img src={AIRPLANE_IMG} alt="비행기 이미지"/>
              </div>
              <div className="square right"/>
            </div>
          </>
        )}


      </div>
    </div>
  )
};

export default Introduce;